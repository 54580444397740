footer {
  background: #fff;
  color: #000;
  text-align: left;
  padding: 53px 43px;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  a:link,
  a:active,
  a:hover,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }
  button.link {
    margin-left: 20px;
  }
}

footer,
.account footer strong {
  font-size: 14px;
  margin-bottom: 0;
}

button.link {
  @include buttonLink();
}

@media only screen and (max-width: 600px) {
  footer button.link {
    display: block;
    width: auto;
    margin: 10px 0;
  }
}
