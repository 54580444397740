//variables

$shootsafe_dark: #18371d;
$shootsafe_green: #4fa35b;
$headerheight: 64px;
$shootsafe_game: #3f6b47;
$chapternav_height: 120px;
$chapternav_height_collapsed: 33px;
$chapternav_border_width: 5px;
$disabled_link_color: #bac3bb;
$error_red: #edb4ab;
$chap_asset_top_margin: 2.6em;

//mixins

@mixin inputStyle() {
  box-sizing: border-box;
  width: 360px;
  height: 64px;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 20px;
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline:0 none;
  &[type='text'] {
    &:disabled {
      background: rgb(214 219 215);
    }
  }
  &[type='submit'] {
    color: #fff;
    font-weight: bold;
    border-radius: 0;
    background: $shootsafe_green;
    &:hover {
      color: $shootsafe_dark;
    }
    &:active {
      border: 5px solid #fff;
      color: #fff;
    }
    &:disabled {
      background: $shootsafe_dark;
      border: 5px solid $shootsafe_green;
      color: $shootsafe_green;
      opacity: 0.5;
    }
  }
}

@mixin buttonStyle() {
  display: inline-block;
  padding: 17px 70px;
  margin: 0 0.3em 0.3em 0;
  text-decoration: none;
  background-color: #fff;
  border: 5px solid $shootsafe_dark;
  font-weight: bold;
  color: $shootsafe_dark;
  text-align: center;
  transition: all 0.3s;
  font-size: 20px;
  font-family: inherit;
  line-height: 1.15;
  outline: 0 none;
  &:hover {
    background-color: $shootsafe_green;
  }
  &:active {
    color: #fff;
    outline: 0 none;
  }
  &:disabled {
    color: rgb(24, 55, 29, 0.3);
    background: #fff;
  }
}

@mixin buttonLink() {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: left;
  color: #00e;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  outline: 0 none;
  &:hover {
    text-decoration: none;
  }
  &:visited {
    color: #551a8b;
  }
}
