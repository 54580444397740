.login {
  &__hero {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    color: $shootsafe_dark;
    font-size: 20px;
    line-height: 26px;
    h1 {
      font-size: 48px;
      line-height: 62px;
      font-weight: bold;
    }
    section {
      text-align: left;
      flex-basis: 60%;
      margin-top: 140px;
      padding: 0 135px 100px 30px;
      display: inline-block;
      &:first-child {
        flex-basis: 40%;
        padding: 38px 38px 100px 90px;
        text-align: left;
        max-width: 500px;
      }
      iframe {
        border: 5px solid $shootsafe_dark;
        padding: 0 !important;
        background-color:$shootsafe_dark;
      }
    }
  }
  &__form {
    padding: 155px 50px 267px;
    background: $shootsafe_dark;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;

    h3 {
      font-size: 24px;
      line-height: 31px;
      font-weight: bold;
      text-transform: uppercase;
    }

    fieldset {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      position: relative;
      label {
        text-align: right;
        flex: 1;
        &:last-child {
          text-align: left;
        }
        .text-primary {
          color: $shootsafe_green !important; //needed to override react-bootstrap default
        }
        output {
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 360px;
          text-align: left;
          top: 70px;
          font-size: 20px;
          font-weight: normal;
          color: $error_red;
          &.networkError {
            font-size: 16px;
          }
        }
      }
      input {
        @include inputStyle();
        margin: 0 24px;
      }
    }
  }
}

$smallScreenLoginVidHorizPad : 18px;

@media only screen and (max-width: 1100px) {
  .login {
    &__hero {
      display: block;
      section,
      section:first-child {
        margin: 50px auto;
        padding: 0 $smallScreenLoginVidHorizPad;
        display: block;
        width: 710px;
        max-width: none;
      }
    }
  }
}

@media only screen and (max-width: 740px) {
  .login {
    &__hero {
      section,
      section:first-child {
        width: 90vw;
      }
      section iframe {
        width:calc(90vw - 2 * #{$smallScreenLoginVidHorizPad});
        height:calc(0.5625 * (90vw - 2 * #{$smallScreenLoginVidHorizPad}));  //16X9
      }
    }
  }
}
