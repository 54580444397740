.page__not-found {
  background: $shootsafe_game;
  min-height: calc(100vh - #{$headerheight});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color:#fff;
  img {
    display: inline-block;
    width: 96vw;
    max-width: 346px;
    margin-top: 31px;
  }
  footer {
    width: 100%;
  }
  h2 {
    font-size: 20px;
    line-height: 26px;
    font-weight:bold;
    text-transform: uppercase;
    margin:40px 10px 0;
  }
  h3 {
    font-size: 18px;
    line-height: 23px;
    font-weight:bold;
    margin:16px 10px 50px;
  }
  > div > button {
      @include buttonStyle();
      &:active {
          border: 5px solid transparent;
      }
  }
}
