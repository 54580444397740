.headerbar {
  height: $headerheight;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 100;
  top: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &__left {
    padding-left: 40px;
    span {
      display: inline-block;
      padding: 0 10px;
      position: relative;
      top: 5px;
      font-size: 18px;
      font-weight: bold;
      color: $shootsafe_green;
      text-transform: uppercase;
    }
  }
  &__right {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    &__login {
      &:link,
      &:visited {
        width: 154px;
        height: 40px;
        background-color: $shootsafe_dark;
        font-weight: bold;
        color: #fff;
        transition: background-color 0.2s;
        font-size: 20px;
        line-height: 26px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        background-color: $shootsafe_green;
      }
      &:active {
        color: $shootsafe_dark;
        background-color: $shootsafe_green;
        border: 5px solid $shootsafe_dark;
      }
    }
    button {
      overflow-x:hidden;
    }
  }
  &__progress {
    height: 100%;
    margin-right: -2px; //used so math on pawPrints width can use all whole numbers and still match mockup
  }
  .btn.btn-primary,
  .btn.btn-primary:active,
  .btn.btn-primary:focus {
    width: 73px;
    height: 50px;
    background: transparent url('../../resources/account.svg') no-repeat;
    cursor: pointer;
    color: transparent;
    padding: 0;
    border: 0 none;
    outline: 0 none;
    box-shadow: none;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .dropdown-menu {
    border: 5px solid #18371d;
    border-top: 0 none;
    top: 5px !important; //overriding inline style provided by react-bootstrap
    left: 41px !important; //overriding inline style provided by react-bootstrap
    a {
      font-size: 24px;
      font-weight: bold;
      line-height: 31px;
      text-align: center;
      text-transform: uppercase;
      padding: 4px 14px;
      transition: all 0.3s;
      &:hover {
        background-color: transparent;
        color: $shootsafe-green;
      }
      span {
        display: block;
        width: 70%;
        border-bottom: 5px solid #18371d;
        margin: 12px auto 2px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .headerbar__left span {
    display: none;
  }
}

@media only screen and (max-width: 720px) {
  .headerbar__progress {
    display: none;
  }
}
