.info {
  min-height: calc(100vh - #{$headerheight});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 47px;
  p {
    text-align: left;
    width: 92vw;
    max-width: 768px;
    margin: 0 auto 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 20px;
  }
  h2,
  h3 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 28px 10px 13px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  section {
    button {
      @include buttonStyle();
      background: $shootsafe-green;
      color: #fff;
      border: 5px solid transparent;
      &:hover {
        background: $shootsafe-dark;
      }
      &:active {
        background: $shootsafe-green;
        border: 5px solid $shootsafe-dark;
        color: $shootsafe-dark;
      }
      &:disabled {
        border: 5px solid rgb(24, 55, 29, 0.3);
        &:hover {
          background: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .info p {
    width: auto;
    max-width: none;
    margin: 0 43px 12px;
  }
}
