.landingWrap {
  min-height: calc(100vh - #{$headerheight});
  background: $shootsafe_dark;
  display: flex;
  flex-direction: column;
  color: #fff;
  white-space: pre-wrap;
  h1 {
    text-transform: uppercase;
    font-size: 24px;
    margin:108px 0 20px;
    line-height:31px;
    font-weight:bold;
  }
  h3 {
    font-size: 18px;
    line-height:23px;
    margin-bottom:30px;
    font-weight:bold;
  }
  input {
      @include inputStyle();
  }
  &_contents {
    flex: 1 0 auto;
    padding:0 43px;
  }
  footer {
    flex-shrink: 0;
  }
}
