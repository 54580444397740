.scrollSnapY {
  scroll-snap-type: y mandatory;
}

.chapter {
  margin-top: -1 * $headerheight;

  background-size: 100%;
  &__bg {
    background-repeat: repeat-y;
    background-position: center top;
    background-size: 100%;
    background-color: $shootsafe_dark;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
    &-bullet {
      background-image: url('../../resources/bgs/bullet.svg');
    }
    &-car {
      background-image: url('../../resources/bgs/car.svg');
    }
    &-tree {
      background-image: url('../../resources/bgs/tree.svg');
    }
    &-snowflake {
      background-image: url('../../resources/bgs/snowflake.svg');
    }
    &-flag {
      background-image: url('../../resources/bgs/flag.svg');
    }
    &-pawprint {
      background-image: url('../../resources/bgs/pawprint.svg');
    }
    &-crosshairs {
      background-image: url('../../resources/bgs/crosshairs.svg');
    }
    &-earprotection {
      background-image: url('../../resources/bgs/earprotection.svg');
    }
  }

  &-haslock.chapter {
    footer,
    button.chapter__complete {
      display: none;
    }
  }

  footer {
    scroll-snap-align: start;
  }
  button.chapter__complete {
    @include buttonStyle();
    padding: 20px 80px;
    background-color: $shootsafe_green;
    border: 0 none;
    color: #fff;
    margin-bottom: 125px;
    border: 5px solid transparent;
    transition: all 0.3s;
    &:hover {
      color: $shootsafe_dark;
    }
    &:active {
      color: #fff;
      border: 5px solid #fff;
    }
  }

  .chapterNav {
    position: sticky;
    z-index: 50;
    height: $chapternav_height;
    top: $headerheight;
    background-color: #fff;
    color: $shootsafe_dark;
    box-sizing: border-box;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    border-top: solid $shootsafe_dark $chapternav_border_width;
    li,
    button {
      height: 100%;
      box-sizing: border-box;
      outline: 0 none;
      transition: background-color 0.15s;
    }
    li.unlocked,
    li.unlocked button {
      color: $shootsafe_dark;
      &:active {
        color: #fff;
      }
    }
    li.unlocked:hover {
      background: $shootsafe_green;
    }
    li.active {
      background: $shootsafe_green;
    }
    li.active,
    li.active button {
      color: #fff;
    }
    &.collapsed {
      height: $chapternav_height_collapsed;
      margin-bottom: $chapternav_height - $chapternav_height_collapsed;
      font-size: 16px;
      transition: font-size 100ms;
      .chapterNav__part {
        display: none;
      }
      ul.chapterNav__parts > li ul {
        height: 100%;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      li {
        flex: 1;
        border: solid $shootsafe_dark $chapternav_border_width;
        border-left-width: 0;
        border-top-width: 0;
      }

      &.chapterNav__parts {
        height: 100%;
        > li {
          height: 100%;
          border-right-width: 0;
          border-bottom-width: 0;
          &:first-child {
            border-left-width: $chapternav_border_width;
          }
          ul {
            width: 100%;
            height: 50%;
          }
          .chapterNav__part {
            li {
              width: 100%;
              height: 100%;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            > li {
              font-size: 24px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    button {
      font-family: inherit;
      font-size: 100%;
      line-height: 1.15;
      margin: 0;
      border: 0 none;
      width: 100%;
      height: 100%;
      background: transparent;
      font-weight: bold;
    }

    button:disabled {
      color: $disabled_link_color;
    }
  }

  .panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    scroll-snap-align: start;
    height: 100vh;
    margin: 0 1vw 40vh 1vw;
    width: 98vw;
    padding-top: $chapternav_height_collapsed + $headerheight; //header height plus collpapsed menu height
    img {
      width: auto; //override html values
      height: auto; //override html values
      max-width: 1024px;
      max-height: calc(
        100vh - 2 * #{$chap_asset_top_margin} - #{$chapternav_height_collapsed} -
          #{$headerheight}
      );
      margin-top: $chap_asset_top_margin;
    }
    &:first-child {
      box-sizing: border-box;
      padding-top: $headerheight + 45px;
      display: block;
      width: 65vw;
      max-width: 1024px;
      margin: 0 auto;
      height: calc(100vh - #{$chapternav_height});

      img {
        height: 100%;
        width: 100%;
        height: auto;
        margin-top: 0;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
      margin-bottom: 55px;
    }
    &-locks {
      height:85vh;
      margin-bottom:15vh;
    }
    &-locks ~ .panel {
      display: none;
    }

    &__continueArrow {
      margin: 0 auto;
      width: 60px;
      height: 30px;
      background: url('../../resources/caretDown.svg') no-repeat;
      background-size: contain;
      animation: MoveUpDown 1s linear infinite;
      margin-top: 20px;
      animation-fill-mode: forwards;
    }
  }
  .panel-unity .panel__continueArrow {
    position: absolute;
    top: 800px;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

@media only screen and (max-width: 1024px) {
  .chapter .panel:first-child {
    width: 92vw;
    img {
      max-width: 100%;
    }
  }
  .chapter .panel img {
    max-width: 92vw;
  }
}

@media only screen and (max-height: 832px), screen and (max-width: 960px) {
  .scrollSnapY {
    scroll-snap-type: none;
  }
  .chapter {
    .panel,
    .panel:first-child {
      margin-bottom: 15vh;
      scroll-snap-align: none;
      height: auto;
      img {
        max-height: none;
      }
    }
    .panel-locks {
      margin-bottom:8vh;
    }
    .panel:first-child {
      box-sizing: border-box;
      margin-bottom: 0;
      min-height: 630px; //prevents nav from overlapping heros, all of which have a standard height. Deliberately small to optimize iPad UX
    }
    .panel.panel-unity {
      min-height: 800px; //unity dimensions to remain fixed per design, so we need container to always be large enough
    }
    footer {
      scroll-snap-align: none;
    }
  }
}

/* ----------- Narrow Desktop ----------- */

@media only screen and (max-width: 1040px) {
  .chapter .chapterNav button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 840px) {
  .chapter .chapterNav button {
    font-size: 13px;
  }
}

@media only screen and (max-width: 680px) {
  .chapter .chapterNav button {
    font-size: 15px;
  }
  .chapter .chapterNav button span {
    display: none;
  }
}

/* ----------- iPad, retina devices ----------- */

/* iPad Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .chapter .chapterNav button {
    font-size: 16px;
  }
}

/*  iPad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .chapter .chapterNav button {
    font-size: 13px;
  }
}

/* iPad Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .chapter .chapterNav button {
    font-size: 16px;
  }
}
/* Smaller retina devices */
@media only screen and (max-width: 680px) and (-webkit-min-device-pixel-ratio: 2) {
  .chapter .chapterNav button {
    font-size: 15px;
  }
  .chapter .chapterNav button span {
    display: none;
  }
}
