$pawCount: 24;

.pawPrints {
  margin: 0;
  padding: 0;
  position: relative;
  //background:blue;
  width: $pawCount * 12px;
  box-sizing: border-box;
  li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 17px;
    height: 17px;
    position: absolute;
    &:nth-child(odd) {
      top: 12px;
    }
    &:nth-child(even) {
      top: 28px;
    }
    left: 0;
    &.paw-earned {
      path {
        fill: $shootsafe_green;
      }
    }
    svg {
      width: 100%;
    }

    @for $i from 1 through $pawCount {
      &:nth-child(#{$i}) {
        left: $i * 11px;
      }
    }
  }
}
