.account {
  min-height: calc(100vh - #{$headerheight});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px;
  h1,
  h3 {
    font-weight: bold;
    text-transform: uppercase;
  }
  h1 {
    font-size: 24px;
    line-height: 31px;
    margin: 22px 10px 0;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 18px 10px 12px;
  }
  strong {
    font-size: 18px;
    line-height: 23px;
    display: inline-block;
    margin-bottom: 15px;
  }
  &__table {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 50px 1fr 62px;
    align-items: end;
    grid-row-gap: 8px;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    max-width: 700px;
    margin-top: 1px;
    img {
      vertical-align: top;
    }
    button {
      @include buttonLink();
      margin: 4px;
      transition: all 0.3s;
      &:hover {
        background: $shootsafe-green;
      }
      &:active {
        path {
          fill: #fff;
        }
        rect {
          stroke: $shootsafe-green;
        }
      }
    }
    div {
      border-bottom: solid 1px $shootsafe-dark;
      &:nth-child(3n) {
        text-align: right;
        padding-bottom: 4px;
      }
      &:nth-child(3n -1) {
        padding: 0 30px 22px 0;
        text-align: left;
      }
      &:nth-child(3n -2) {
        text-align: left;
        padding-bottom: 15px;
      }
      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3) {
        border-bottom: 0 none;
      }
    }
  }
  section {
    margin-bottom: 50px;
    > button {
      @include buttonStyle();
      background: $shootsafe-green;
      color: #fff;
      border: 5px solid transparent;
      &:hover {
        background: $shootsafe-dark;
      }
      &:active {
        background: $shootsafe-green;
        border: 5px solid $shootsafe-dark;
        color: $shootsafe-dark;
      }
      &:disabled {
        border: 5px solid rgb(24, 55, 29, 0.3);
        &:hover {
          background: #fff;
        }
      }
      &.account_logout {
        margin-top: 25px;
        background: #fff;
        color: $shootsafe-dark;
        border: 5px solid $shootsafe-dark;
        padding: 17px 140px;
        &:hover {
          background: $shootsafe-green;
        }
        &:active {
          color: #fff;
          border: 5px solid transparent;
        }
      }
    }
  }
}
