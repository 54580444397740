$vidFrameWrapWidthWide: 64vw;
$vidFrameWrapWidthMid: 74vw;
$vidFrameWrapWidthNarrow: 90vw;

@function sixteenNineHeightFromWidth($height) {
  $result: calc(9 * #{$height} / 16);
  @return $result;
}

.panel-vimeoPlayer {
  flex-direction: column;
  justify-content: flex-start;
  h3 {
    margin-top: $chap_asset_top_margin;
    font-size: 1.25em;
    line-height: 1.625em;
    margin-bottom: 1.25em;
    color: #fff;
    font-weight: bold;
  }
  & > div {
    height: sixteenNineHeightFromWidth($vidFrameWrapWidthWide);
    width: $vidFrameWrapWidthWide;
    max-height: calc(100vh - 16em);

    iframe {
      height: 100%;
      width: 100%;
    }
  }
  iframe body {
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 1280px) {
  .panel-vimeoPlayer > div {
    height: sixteenNineHeightFromWidth($vidFrameWrapWidthMid);
    width: $vidFrameWrapWidthMid;
  }
}

@media only screen and (max-width: 1088px) {
  .panel-vimeoPlayer > div {
    height: sixteenNineHeightFromWidth($vidFrameWrapWidthNarrow);
    width: $vidFrameWrapWidthNarrow;
  }
}
