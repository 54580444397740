@import "~bootstrap/scss/bootstrap";

@import 'variables';

@import 'components/headerbar';
@import 'components/footerbar';
@import 'components/login';
@import 'components/progresspaws';
@import 'components/landing';
@import 'components/chapter';
@import 'components/vimeoplayer';
@import 'components/unity';
@import 'components/overlay';
@import 'components/notfound';
@import 'components/info';
@import 'components/account';
@import 'App';


body {
  margin: 0;
  font-family: 'Mali', arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

