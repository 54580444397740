.modal.show {
  background: rgba(255, 255, 255, 0.7);
  .modal-dialog {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 98%;
    background: #fff;
    width: 627px;
    border: 8px solid #236130;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #1f5f29;
    text-align: center;
  }
  .modal-content {
    background: url('../../resources/graphicsLessonComplete.svg') top -23px center
      no-repeat;
    border: 0 none;
  }
  &.modal-networkError {
    .modal-content {
      background: none;
      h3 {
        max-width: 440px;
        line-height: 26px;
      }
    }
    .modal-body {
      margin: 10px auto;
    }
    button {
      margin-bottom: 43px;
      background: $shootsafe-green url("data:image/svg+xml,%3Csvg width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.737 0.167708C24.5652 0.185988 25.2218 0.872216 25.2035 1.70044L24.976 12.0078C24.9577 12.8361 24.2715 13.4927 23.4433 13.4744C22.615 13.4561 21.9584 12.7699 21.9767 11.9416L22.2042 1.63424C22.2225 0.806018 22.9087 0.149428 23.737 0.167708Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7067 10.9998C11.7751 10.1742 12.4998 9.56033 13.3254 9.62873L23.6001 10.48C24.4257 10.5484 25.0395 11.2731 24.9711 12.0987C24.9027 12.9243 24.178 13.5382 23.3524 13.4698L13.0777 12.6185C12.2521 12.5501 11.6383 11.8254 11.7067 10.9998Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2169 5.97656C7.12653 5.97656 3 10.1031 3 15.1934C3 20.2838 7.12653 24.4103 12.2169 24.4103C15.4213 24.4103 18.2447 22.7762 19.8985 20.289C20.3572 19.5991 21.2882 19.4117 21.9781 19.8704C22.668 20.3291 22.8554 21.2601 22.3967 21.95C20.2105 25.238 16.4677 27.4103 12.2169 27.4103C5.46968 27.4103 0 21.9406 0 15.1934C0 8.44624 5.46968 2.97656 12.2169 2.97656C16.7407 2.97656 20.6883 5.43619 22.7984 9.0839C23.2132 9.801 22.9682 10.7186 22.2511 11.1334C21.534 11.5482 20.6164 11.3032 20.2016 10.5861C18.6057 7.82724 15.6265 5.97656 12.2169 5.97656Z' fill='white'/%3E%3C/svg%3E%0A")
        no-repeat left 17px center;
      position: relative;
      &:focus {
        outline: 0 none;
      }
    }
    .spinner-border {
      position: absolute;
      right: 15px;
    }
  }
  &.modal-chapterComplete {
    .modal-content {
      background: url('../../resources/graphicsChapComplete.svg') top 0 center
        no-repeat;
    }
    .modal-body {
      span {
        display: inline-block;
        width: 460px;
        margin-top: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        font-style: italic;
      }
    }
  }
  .modal-header {
    border-bottom: 0 none;
    justify-content: center;
    padding-bottom: 0;
  }
  .modal-footer {
    border: 0 none;
    justify-content: center;
    .btn {
      border-radius: 0;
      font-size: 20px;
      font-weight: bold;
      width: 360px;
      padding: 14px;
      border: 0 none;
      transition: all 0.3s;
    }
    .btn-primary {
      background-color: $shootsafe-green;
      &:hover {
        background-color: $shootsafe-dark;
      }
    }
    .btn-secondary {
      background: #fff;
      color: $shootsafe-dark;
      border: 4px solid $shootsafe-dark;
      &:hover {
        background: $shootsafe-green;
      }
    }
  }
  .h4.modal-title {
    font-size: 56px;
    font-weight: bold;
  }
  .modal-body {
    padding-top: 0;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    h4 {
      font-size: 18px;
      margin: 28px 0 10px;
    }
  }
}

