.panel-unity {
  position: relative;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 140px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
